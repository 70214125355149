import Url from "url-parse";
export default function loadExternalScripts(satellite, assetDomain) {
  window._satellite = window._satellite || satellite;
  let urlToActionMapping = {};
  satellite.__registerScript = function(url, f) {
    urlToActionMapping[
      url
    ].LaunchViewerLoadedCode = `[[[VIEWERBEGIN]]]function (){${f.toString()}}[[[VIEWEREND]]]`;
  };
  let rules = satellite._container.rules;
  rules.forEach(rule => {
    let actions = rule.actions;
    if (!actions) {
      return;
    }
    rule.actions.forEach(action => {
      if (action.modulePath !== "core/src/lib/actions/customCode.js") {
        return;
      }
      let settings = action.settings;
      if (settings.isExternal && typeof settings.source === "string") {
        urlToActionMapping[settings.source] = settings;
        let path = Url(settings.source).pathname;
        let url = "https://" + assetDomain + path;
        addScript(url);
      }
    });
  });
  console.log("exteral files loaded");
}

function addScript(src, cb) {
  var s = document.createElement("script");
  s.setAttribute("src", src);
  s.onload = function() {
    typeof cb === "function" && cb(true);
  };
  s.onerror = function() {
    typeof cb === "function" && cb(false);
  };
  document.body.appendChild(s);
}
