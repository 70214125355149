<template>
  <pre><code class="customCode">
    {{codeString}}</code></pre>
</template>

<script>
import { js as beautify } from "js-beautify";

export default {
  name: "Code",
  props: ["code"],
  data() {
    return {
      codeString: ""
    };
  },
  beforeMount() {
    let code = this.$props.code;
    //console.log(code);
    let toggleCodeFormat = false;
    function convertFunctions(r) {
      if (typeof r === "object") {
        for (let k in r) {
          let v = r[k];
          if (typeof v === "object") {
            convertFunctions(v);
          } else {
            if (typeof v === "function") {
              if (toggleCodeFormat) {
                r[
                  k
                ] = `[[[VIEWERBEGIN]]]function (){${v.toString()}}[[[VIEWEREND]]]`;
              } else {
                r[k] = v.toString();
              }
            }
          }
        }
      }
    }
    convertFunctions(code);

    let codeString = JSON.stringify(code);
    let split = codeString.split('"[[[VIEWERBEGIN]]]');
    for (let i = 1, l = split.length; i < l; i++) {
      let str = split[i];
      if (str.indexOf('[[[VIEWEREND]]]"') !== -1) {
        let r = str.split('[[[VIEWEREND]]]"');
        r[0] = r[0].replace(/(\\")/g, '"');
        split[i] = r.join("");
      }
    }
    codeString = split.join("");
    this.codeString = beautify(codeString);
  },
  mounted() {
    let nodes = Array.from(this.$el.querySelectorAll("code"));
    if (window.hljs) {
      nodes.forEach(node => {
        node.innerText = beautify(node.innerText);
        window.hljs.highlightBlock(node);
      });
    }
  }
};
</script>
