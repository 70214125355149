/* eslint-disable prettier/prettier */

<template>
  <h2 class="companyTitle">
    {{ satellite_container.property.name }} <br />
    <span
      >&nbsp;<Tag
        rounded
        severity="info"
        :value="'Last updated ' + getLastUpdated"
        class="builddate"
        icon="pi pi-calendar"
      ></Tag>
    </span>
  </h2>
  <section id="main" style="padding: 0px">
    <TabView :activeIndex="activeIndex">
      <TabPanel header="Summary">
        <Summary
          :buildInfo="satellite_container.buildInfo"
          :company="satellite_container.company"
          :property="satellite_container.property"
          :RuleListArray="RuleListArray"
        />
      </TabPanel>
      <TabPanel :header="RuleTabTitle">
        <LazyRules v-bind:RuleListArray="RuleListArray" />
      </TabPanel>
      <TabPanel :header="DataElementTabTitle">
        <DataElements v-bind:DataElementListObj="DataElementListObj" />
      </TabPanel>
      <TabPanel :header="ExtensionTabTitle">
        <Extensions v-bind:ExtensionListObj="ExtensionListObj" />
      </TabPanel>
    </TabView>
  </section>
</template>
<script>
import Summary from ".//Summary";
import DataElements from ".//DataElements";
import Extensions from ".//Extensions";

import { defineAsyncComponent } from "vue";
const LazyRules = defineAsyncComponent(() => import("./Rules.vue"));
export default {
  name: "Result",
  props: ["satellite_container"],
  computed: {
    RuleTabTitle: function() {
      return "Rules (" + this.satellite_container.rules.length + ")";
    },
    DataElementTabTitle: function() {
      return (
        "Data Elements (" +
        Object.keys(this.satellite_container.dataElements).length +
        ")"
      );
    },
    ExtensionTabTitle: function() {
      return (
        "Extensions (" +
        Object.keys(this.satellite_container.extensions).length +
        ")"
      );
    },
    RuleListArray: function() {
      return this.satellite_container.rules;
    },
    DataElementListObj: function() {
      return this.satellite_container.dataElements;
    },
    ExtensionListObj: function() {
      return this.satellite_container.extensions;
    },
    getDate: function() {
      return new Date(this.satellite_container.buildInfo.buildDate);
    },

    getLastUpdated: function() {
      let date_build = new Date(this.satellite_container.buildInfo.buildDate);
      let date_now = new Date();
      let seconds = Math.floor((date_now - date_build) / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);
      hours = hours - days * 24;
      return Math.abs(days) + " days and " + hours + " hours ago";
    }
  },
  components: {
    Summary,
    LazyRules,
    DataElements,
    Extensions
  },
  data() {
    return {
      activeIndex: 0,
      propertyName: this.satellite_container.property.name
    };
  }
};
</script>

<style>
.companyTitle {
  font-size: 1.8rem;
  color: rgb(228, 228, 228);
  margin-left: 65px;
  margin-bottom: 25px;
  line-height: 1.8rem;
}
.p-tag.p-tag-rounded {
  height: 24px !important;
  background: rgb(248, 190, 81) !important;
}
.builddate .p-tag-value {
  font-size: 0.7rem !important;
  font-style: italic;
  line-height: 0.8;
}
.p-tabview .p-tabview-nav {
  padding: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.p-tabview-title {
  line-height: 0.5;
  font-size: 1rem;
  color: rgb(228, 228, 228);
}
.p-fieldset .p-fieldset-legend {
  line-height: 0.1;
  color: rgb(228, 228, 228);
  padding: 0.5rem !important;
}
.p-tag {
  font-size: 0.85rem !important;
}
.p-fieldset {
  margin-bottom: 18px;
}
.p-fieldset .p-fieldset-content {
  padding: 0.5rem !important ;
}
#chipstart {
  margin: 0 20px 0 0;
  padding: 0 0 0 0;
}
.p-tooltip {
  height: fit-content !important;
  max-width: 600px !important;
  width: fit-content;
  page-break-inside: avoid;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.p-tooltip-text {
  font-size: 0.9rem;
  word-wrap: break-word;
}
.p-button {
  font-weight: 500 !important;
  min-width: 4rem !important;
  width: auto !important;
  height: 30px !important;
  color: black !important;
  font-size: 0.8rem !important;
}
.p-button-primary {
  font-weight: 500 !important;
  min-width: 4rem !important;
  width: auto !important;
  height: 30px !important;
  color: rgb(228, 228, 228) !important;
  font-size: 0.8rem !important;
  background: #3f51b5 !important;
}

.code-tips {
  height: fit-content !important;
  max-height: max-content;
  max-width: 600px !important;
  width: fit-content;
  page-break-inside: avoid;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
}

.customCode {
  padding: 0px;
}
.codeModal {
  word-wrap: break-word;
  max-height: 60% !important;
  max-width: 700px !important;
  background: #1e1e1e;
  padding: 5px !important;
  border-radius: 5px !important;
}
.modalHeader {
  color: rgb(228, 228, 228);
  font-weight: 600;
  font-size: 0.9rem;
}
pre {
  word-wrap: break-word;
  white-space: pre-wrap;
  margin: 1px;
  padding: 0px;
}
</style>
