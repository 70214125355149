import { createApp } from "vue";
import App from "./App.vue";
const app = createApp(App);

import PrimeVue from "primevue/config";

import "primevue/resources/themes/md-dark-indigo/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import Fieldset from "primevue/fieldset";
import Tag from "primevue/tag";
import Tooltip from "primevue/tooltip";
import Button from "primevue/button";
import "vue-neat-modal/dist/vue-neat-modal.css";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";

app.use(PrimeVue);
app.use(ToastService);
app.component("Tooltip", Tooltip);
app.directive("tooltip", Tooltip);
app.component("Tag", Tag);
app.component("Fieldset", Fieldset);
app.component("InputText", InputText);
app.component("TabView", TabView);
app.component("TabPanel", TabPanel);
app.component("Button", Button);
app.component("Toast", Toast);
app.mount("#app");
