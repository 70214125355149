<template>
  <Fieldset
    :legend="name"
    v-for="(de, name) in DataElementListObj"
    :key="name"
    style="margin-top: 10px; margin-bottom: 25px"
  >
    <div style="padding: 0px">
      <Button
        :label="getExtension(de.modulePath)"
        icon="pi pi-folder"
        class="deButtons primaryTag"
      />
      <Button
        :label="'[storage duration] ' + storageDuration(de.storageDuration)"
        icon="pi pi-cog"
        class="deButtons settingTag"
      />
      <Button
        :label="'[default value] ' + defaultValue(de.defaultValue)"
        icon="pi pi-cog"
        v-if="checktype(de.defaultValue) !== 'undefined'"
        class="deButtons settingTag"
      />
      <Button
        :label="'[force lowercase] ' + de.forceLowerCase"
        icon="pi pi-cog"
        v-if="de.forceLowerCase == true"
        class="deButtons settingTag"
      />
      <Button
        :label="'[clean text] ' + de.cleanText"
        icon="pi pi-cog"
        v-if="de.cleanText == true"
        class="deButtons settingTag"
      />
      <Modal modal-transition="move-up" modalClass="codeModal">
        <template #activator="props">
          <Button
            :label="'view detail settings'"
            icon="pi pi-search-plus"
            v-if="checktype(de.settings) !== 'undefined'"
            class="deButtons detailTag"
            v-tooltip.right.mouseover="beautify(de.settings)"
            v-bind="props"
          />
        </template>
        <template #default="{}">
          <span class="modalHeader">{{ getExtension(de.modulePath) }}</span
          ><br />
          <Code v-if="de.settings" v-bind:code="de.settings" />
        </template>
      </Modal>
    </div>
  </Fieldset>
</template>
<style scoped>
.deButtons {
  display: inline-flex;
  margin: 2px 10px 5px 10px !important;
}
.primaryTag {
  background: #3f51b5;
  color: rgb(228, 228, 228) !important;
  cursor: unset;
}
.settingTag {
  background: #a2cec9;
  cursor: unset;
}
.detailTag {
  background: #90caf9;
  padding-right: 40px !important;
  padding-left: 40px !important;
}
</style>
<script>
import { Modal } from "vue-neat-modal";
import Code from "./Code";
import { js as beautify } from "js-beautify";
export default {
  name: "DataElements",
  props: ["DataElementListObj"],
  components: { Code, Modal },
  data() {
    return {};
  },
  methods: {
    beautify(cd) {
      return (
        '<pre class="code-tips">' + beautify(JSON.stringify(cd)) + "</pre>"
      );
    },
    checktype(obj) {
      return typeof obj;
    },
    getExtension(modualpath) {
      return (
        "[" +
        modualpath.split("/")[0].toUpperCase() +
        "] " +
        modualpath
          .split("/")[4]
          .substring(0, modualpath.split("/")[4].length - 3)
      );
    },
    storageDuration(duration) {
      if (duration) return duration;
      else {
        return "none";
      }
    },
    defaultValue(defaultValue) {
      if (defaultValue == "") return "empty string";
      else {
        return defaultValue;
      }
    }
  }
};
</script>
