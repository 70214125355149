<template>
  <Fieldset legend="Property Info" class="fieldsetinfo">
    <div>
      <span class="cardInfo">Domains:</span> {{ property.settings.domains }}
    </div>
    <div>
      <span class="cardInfo">Run rule components in sequence:</span>
      {{ property.settings.ruleComponentSequencingEnabled }}
    </div>
    <div>
      <span class="cardInfo"
        >Return an empty string for missing data element:</span
      >
      {{ property.settings.undefinedVarsReturnEmpty }}
    </div>
  </Fieldset>
  <Fieldset legend="Build Info" class="fieldsetinfo">
    <div>
      <span class="cardInfo">Environment:</span>&nbsp;&nbsp;{{ libraryEnv }}
    </div>
    <div>
      <span class="cardInfo">OrgID:</span>&nbsp;&nbsp;{{ company.orgId }}
    </div>
    <div>
      <span class="cardInfo">Build Date:</span>&nbsp;&nbsp;{{
        buildInfo.buildDate
      }}
    </div>
    <div>
      <span class="cardInfo">Turbine Build Date:</span>&nbsp;&nbsp;{{
        buildInfo.turbineBuildDate
      }}
    </div>
    <div>
      <span class="cardInfo">Turbine Version:</span>&nbsp;&nbsp;{{
        buildInfo.turbineVersion
      }}
    </div>
  </Fieldset>

  <Fieldset legend="Rules Overview" class="fieldsetinfo">
    <span class="cardInfo" :set="(actionSize = myData.action.size)">
      Number of Rules: </span
    >{{ RuleListArray.length }}<br />
    <span class="cardInfo" :set="(actionSize = myData.action.size)">
      Rules with Custom Code in Action: </span
    >{{ actionSize }} ({{
      Math.round((myData.action.size / RuleListArray.length) * 100)
    }}%)
    <span
      class="smallinfo"
      v-if="Math.round((actionSize / RuleListArray.length) * 100) > 25"
    >
      &nbsp;*Custom code is prone to human error, try to mimimize the usage of
      it.</span
    >
    <br />

    <span class="cardInfo" :set="(conditionSize = myData.condition.size)">
      Rules with Custom Code in Condition: </span
    >{{ conditionSize }} ({{
      Math.round((conditionSize / RuleListArray.length) * 100)
    }}%)
    <br />

    <span class="cardInfo" :set="(noActionSize = myData.noAction.length)"
      >Rules without Action:</span
    >
    {{ noActionSize }} &nbsp;
    <span v-if="myData.noAction.length > 0"
      ><span class="smallinfo">
        <Modal modal-transition="move-up" modalClass="codeModal">
          <template #activator="props">
            <a v-bind="props" class="seelist"> View Rules</a>
          </template>
          <template #default="{}">
            <div class="listModal">
              <p v-for="item in myData.noAction" :key="item">
                {{ item }}
              </p>
            </div>
          </template>
        </Modal>
        &nbsp; &nbsp;*In most cases, these rules should be disabled.<br />
      </span>
    </span>

    <hr style="width:100%;text-align:center;margin:0px;padding:5px;" />
    <span class="cardInfo">Event Type Usage</span><br />
    <div style="margin-left:10px;">
      <span class="cardInfo">Click:</span> {{ myData.clickCount }}<br />
      <span class="cardInfo">Window Load:</span> {{ myData.windowLoadedCount
      }}<br />
      <span class="cardInfo">Direct Call:</span> {{ myData.directCallCount
      }}<br />
      <span class="cardInfo">Library Top:</span> {{ myData.libraryLoadedCount
      }}<br />
      <span class="cardInfo">DOM Ready:</span> {{ myData.domReadyCount }}<br />
      <span class="cardInfo">pageBottom:</span> {{ myData.pageBottomCount
      }}<br />
      <span class="cardInfo">Custom Events:</span> {{ myData.customEventCount
      }}<br />
      <span class="cardInfo">Others:</span> {{ myData.otherCount }}<br />
    </div>
  </Fieldset>
</template>
<style scoped>
.listModal p {
  line-height: 30px;
  margin: 0px;
  width: fit-content;
  padding: 0px;
}
.listModal {
  border: solid 1px;
  padding: 20px;
  border-color: #90909040;
}
.seelist {
  width: 100% !important;
  color: white;
}
.seelist:hover {
  cursor: pointer;
}

.smallinfo {
  width: 100% !important;
  color: red !important;
  font-size: 15px;
}
.fieldsetinfo {
  display: inline-flex;
  width: 100%;
  background: #1e1e1e;
  vertical-align: top;
  color: #f1f1f1;
}
.cardInfo {
  font-weight: 700;
  color: #30af98;
}
</style>
<script>
import Card from "primevue/card";
import { Modal } from "vue-neat-modal";
export default {
  components: { Card, Modal },
  name: "Summary",
  props: ["buildInfo", "company", "property", "environment", "RuleListArray"],
  data() {
    return { libraryEnv: this.getEnv(), myData: this.countCustomInfo() };
  },
  watch: {
    property: function() {
      this.myData = this.countCustomInfo();
    }
  },
  methods: {
    getEnv() {
      if (typeof this.environment !== "undefined") {
        return this.environment.stage;
      } else {
        return this.buildInfo.environment;
      }
    },
    countCustomInfo() {
      var custData = {};
      let actionCustomCode = [];
      let conditionCustomCode = [];
      let noAction = [];
      let eventName = [];
      var rulesArray = this.RuleListArray;
      for (let i = 0; i < rulesArray.length; i++) {
        if (rulesArray[i].events.length != 0) {
          for (let l = 0; l < rulesArray[i].events.length; l++) {
            eventName.push(
              rulesArray[i].events[l].modulePath.substring(
                rulesArray[i].events[l].modulePath.lastIndexOf("/") + 1,
                rulesArray[i].events[l].modulePath.length - 3
              )
            );
          }
        }
        if (rulesArray[i].actions.length == 0) {
          noAction.push(rulesArray[i].name);
        } else {
          for (let k = 0; k < rulesArray[i].actions.length; k++) {
            if (
              rulesArray[i].actions[k].modulePath.indexOf("customCode.js") > -1
            ) {
              actionCustomCode.push(rulesArray[i].name);
            }
          }
        }
        for (let j = 0; j < rulesArray[i].conditions.length; j++) {
          if (
            rulesArray[i].conditions[j].modulePath.indexOf("customCode.js") > -1
          ) {
            conditionCustomCode.push(rulesArray[i].name);
          }
        }
      }
      custData.action = new Set(actionCustomCode);
      custData.condition = new Set(conditionCustomCode);
      custData.noAction = noAction;
      custData.eventName = eventName;
      custData.clickCount = eventName.filter(x => x == "click").length;
      custData.windowLoadedCount = eventName.filter(
        x => x == "windowLoaded"
      ).length;
      custData.directCallCount = eventName.filter(
        x => x == "directCall"
      ).length;
      custData.libraryLoadedCount = eventName.filter(
        x => x == "libraryLoaded"
      ).length;
      custData.domReadyCount = eventName.filter(x => x == "domReady").length;
      custData.pageBottomCount = eventName.filter(
        x => x == "pageBottom"
      ).length;
      custData.customEventCount = eventName.filter(
        x => x == "customEvent"
      ).length;
      custData.otherCount =
        custData.eventName.length -
        custData.clickCount -
        custData.windowLoadedCount -
        custData.directCallCount -
        custData.domReadyCount -
        custData.pageBottomCount -
        custData.libraryLoadedCount -
        custData.customEventCount;
      return custData;
    }
  }
};
</script>
