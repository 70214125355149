<script>
import LibrarySelect from "./components/LibrarySelect";
export default {
  name: "App",
  components: {
    LibrarySelect
  }
};
</script>

<template>
  <LibrarySelect />
</template>
