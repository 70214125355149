import Url from "url-parse";
export default function addFrame(launchjspath, callbackString) {
  var id = "launchFrame";
  var domain = Url(launchjspath || "").hostname;
  if (window.location.hostname === domain) {
    console.error(
      "provide external domain e.g. https://assets.adobedtm.com/...   provided:",
      domain
    );
    return;
  }
  var frame = document.getElementById(id);
  if (frame) {
    if (frame.dataset.url === launchjspath) {
      return frame;
    }
    frame.parentElement.removeChild(frame);
  }
  var html = `<html>
    <head>
        <meta http-equiv="Content-Security-Policy" content="default-src 'self'; script-src 'unsafe-inline' 'self' ${domain}">
        <meta http-equiv="X-Content-Security-Policy" content="default-src 'self'; script-src 'unsafe-inline' 'self' ${domain}">
        <meta http-equiv="X-WebKit-CSP" content="default-src 'self'; script-src 'unsafe-inline' 'self' ${domain}">
    </head>
    <body>
        <script src="${launchjspath}" async onload="parent.${callbackString}(window._satellite,'${domain}')"></script>
    </body>
    </html>`;

  var iframe = document.createElement("iframe");
  iframe.style.width = "1px";
  iframe.style.height = "1px";
  iframe.style.margin = "0px";
  iframe.style.border = "0px";
  iframe.style.visibility = "hidden";
  iframe.id = id;
  iframe.dataset.url = launchjspath;
  document.body.appendChild(iframe);
  iframe.contentWindow.document.open();
  iframe.contentWindow.document.write(html);
  iframe.contentWindow.document.close();
  return iframe;
}
