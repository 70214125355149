<template>
  <div class="libSelect">
    <form @submit.prevent="urlSubmit(url)">
      <span class="p-input-icon-left p-float-label">
        <i class="pi pi-search" />
        <InputText
          type="text"
          v-model="url"
          placeholder="https://assets.adobedtm.com/...."
          autocomplete="on"
          required="required"
        />
        <Toast position="center" />
      </span>
    </form>

    <br />
    <div>
      <Result
        v-if="satellite"
        v-bind:satellite_container="satellite._container"
      />
    </div>
  </div>
  <footer id="footer">
    <div class="copyright">
      &copy; 2021 hkdigitalanalytics.com &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <a
        href="https://www.linkedin.com/in/mlnet"
        target="new"
        class="icon fa-linkedin"
      >
        <span class="label">LinkedIn</span>
      </a>
    </div>
  </footer>
</template>

<style scoped>
.libSelect {
  margin-top: 20px;
}

.p-input-icon-left {
  display: block;
  position: relative;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: 30px;
  top: 22px;
}
input {
  height: 30px;
  color: black;
  border-radius: 5px 5px 5px 5px;
  background-color: whitesmoke;
}
.p-float-label > label {
  left: 0.5rem;
  color: #066cbe;
  transition-duration: 0.2s;
}
.p-input-icon-left > i:first-of-type {
  left: 0.5rem;
  color: rgba(213, 21, 21, 0.6);
}
</style>

<script>
import Result from ".//Result";
import addFrame from "../scripts/addframe";
import loadExternalScripts from "../scripts/loadExternalScripts";
export default {
  name: "LibrarySelect",
  components: {
    Result
  },

  data() {
    return {
      messages: [],
      url: "",
      launchUrl: "",
      frame: undefined,
      satellite: false
    };
  },

  methods: {
    loadLibrary() {
      this.$emit("url-submit", this.url);
    },
    close() {
      this.$emit("removeLibrary");
    },
    urlSubmit(v) {
      console.log("URL Entered: " + v);
      if (
        v.indexOf("http") > -1 &&
        v.indexOf(".js") > -1 && 
        window.location.host.indexOf('satelliteviewer.net') > -1
      ) {
        window.loadcallback = this.loadcallback;
        if (v === this.launchUrl) {
          console.log("same library URL was entered");
          this.url = "";
          return;
        }
        this.launchUrl = v;
        this.frame = addFrame(v, "loadcallback");
        this.url = "";
      } else {
        //this.url = "";
        this.$emit("removeLibrary");
        console.log("not a valid url");
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Not a valid library URL",
          life: 2500
        });
      }
    },
    loadcallback(sat, assetDomain) {
      if (!sat) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "satellite container not found, please check library URL",
          life: 2500
        });
      } else {
        window._satellite = sat;
        loadExternalScripts(sat, assetDomain);
        this.satellite = sat;
      }
    },
    removeLibrary() {
      this.url = "";
      this.frame.parentNode.removeChild(this.frame);
      this.frame = undefined;
      this.launchUrl = "";
      window._satellite = undefined;
      this.satellite = false;
    }
  }
};
</script>
