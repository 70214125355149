<template>
  <Card class="extCard" v-for="(ext, name, key) in ExtensionListObj" :key="key">
    <template #title>
      <span class="extCardTitle">{{ ext.displayName }}</span>
    </template>
    <template #content>
      <Modal modal-transition="move-up" modalClass="codeModal">
        <template #activator="props">
          <Button
            :label="getLabel(ext.settings)"
            :icon="getIcon(ext.settings)"
            :class="getClass(ext.settings)"
            v-bind="props"
          />
        </template>
        <template #default="{}">
          <span class="modalHeader">{{ ext.displayName }}</span
          ><br />
          <Code v-if="ext.settings" v-bind:code="ext.settings" />
        </template>
      </Modal>
    </template>
  </Card>
</template>
<style scoped>
.extCard {
  width: fit-content;
  display: inline-flex;
  text-align: center;
  margin: 0px !important;
}
.extCardTitle {
  font-size: 0.9rem !important;
  font-weight: 700;
  margin: 0px !important;
}
.p-card {
  margin: 10px !important;
}
.p-fieldset {
  border: 1px solid #ffffff1f;
  color: #ffffffde;
  border-radius: 4px;
  width: fit-content;
  display: inline-flex;
  height: 80px;
  margin: 10px;
}

.p-tag-module {
  margin: 10px !important;
}
.noDetail {
  background: #f48fb1;
}
.yesDetail {
  background: #90caf9;
}
</style>
<script>
import { Modal } from "vue-neat-modal";
import Card from "primevue/card";
import Code from "./Code";
import { js as beautify } from "js-beautify";
export default {
  components: { Card, Code, Modal },
  name: "Extensions",
  props: ["ExtensionListObj"],
  data() {
    return {};
  },
  methods: {
    beautify(cd) {
      return (
        '<pre class="code-tips">' + beautify(JSON.stringify(cd)) + "</pre>"
      );
    },
    checktype(obj) {
      return typeof obj;
    },
    getLabel(ext) {
      if (ext) {
        return "view detail settings";
      } else {
        return "no settings available";
      }
    },
    getModule(key) {
      let mykey = key.toString();
      return mykey.substring(mykey.lastIndexOf("/lib/") + 5, mykey.length);
    },
    getIcon(ext) {
      if (ext) {
        return "pi pi-search-plus";
      } else {
        return "pi pi-info-circle";
      }
    },
    getClass(ext) {
      if (ext) {
        return "yesDetail";
      } else {
        return "noDetail";
      }
    }
  }
};
</script>
